import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Button } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { eventlist } from '../data/eventlist';
import Eventcard from './Eventcard';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Eventhome = () => {
  // Filter the eventlist array to include only items with type equal to "competition"
  const competitionEvents = eventlist.filter(event => event.type === "competition");

  const settings = {
    dots:true,
    infinite:true,
    speed:500,
    slidesToShow:2,
    slidesToScroll:1,
    autoplay: false,
    arrows:false,
    centerMode:false,
    initialSlide: 0,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1110, 
        settings: {
          slidesToShow: 2, 
        },
      },
      {
        breakpoint: 955, 
        settings: {
          slidesToShow: 2, 
        },
      },
      {
        breakpoint: 821, 
        settings: {
          slidesToShow: 1, 
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1, 
        },
      },
    ],
  };
  
  return (
    <div className='flex-col bg-[#F2F2F4] pb-8 z-50 ' style={{ fontFamily: "kayak" }}>
      <div>
        <div className=''>
          <p className='text-black text-center sm:mb-10 mb-2 font-extrabold md:text-6xl sm:text-4xl text-4xl pt-16 pb-16'>Competition</p>
          <div className='w-full '>
            <div className='mt-0 mb-4'>
              <Slider {...settings}>
                {competitionEvents.map((event) => (
                  <div className='' key={event.id}>
                    <div className='flex justify-center items-center'> 
                      <Eventcard event={event} /> 
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Eventhome;
