import React, { useState, useRef, useEffect } from 'react';
import { RiMenu2Line } from "react-icons/ri";

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const navbarRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (navbarRef.current && !navbarRef.current.contains(event.target) && !event.target.closest('.menu')) {
              setIsOpen(false);
            }
          }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [navbarRef]);

    return (
        <div style={{ fontFamily: "kayak" }}>
            <div className='absolute bg-transparent flex p-6 w-full top-0 left-0 right-0 h-16 z-40' style={{ fontFamily: "kayak" }} ref={navbarRef}>
                <ul className='md:flex justify-center gap-20 items-center p-2 list-none w-full text-xl hidden  '>
                    <li><a href='/' className='text-[#007C00] hover:text-[#12B296] transition duration-150 ease-out hover:ease-in hover:text-3xl'>Home</a></li>
                    <li><a href='/workshop' className='text-[#007C00] hover:text-[#12B296] transition duration-150 ease-out hover:ease-in hover:text-3xl'>Workshop</a></li>
                    <li><a href='/competition' className='text-[#007C00] hover:text-[#12B296] transition duration-150 ease-out hover:ease-in hover:text-3xl'>Competition</a></li>
                </ul>
                <div className='fixed  md:hidden w-full'>
                    {!isOpen ? <div className='flex' onClick={() => setIsOpen(!isOpen)}><RiMenu2Line size={40} /></div> : null}
                </div>
            </div>
            {
                isOpen && (
                    <div
                        className={`fixed inset-0 w-4/6 bg-[#232323] z-50 text-white opacity-95 backdrop-blur-3xl menu transition duration-300 ease-in-out transform ${isOpen ? 'translate-x-0' : '-translate-x-full'}`}
                        style={{ overflowY: 'auto' }} 
                    >
                        <ul className='flex flex-col pl-4 pt-10 list-none text-xl gap-10'>
                            <div className='flex justify-center items-center text-4xl text-[#007C00]'>
                                <h1>Py</h1><img src='images/pythonlogo.png' alt='python' className='h-12 w-12'></img><h1>week</h1>
                            </div>
                            <hr></hr>
                            <li><a href='/' onClick={(e) => e.stopPropagation()} className='hover:text-[#12B296]'>Home</a></li>
                            <li><a href='workshop' onClick={(e) => e.stopPropagation()} className='hover:text-[#12B296]'>Workshop</a></li>
                            <li><a href='/competition' onClick={(e) => e.stopPropagation()} className='hover:text-[#12B296]'>competiton</a></li>
                        </ul>
                    </div>
                )}
        </div>
    );
}

export default Navbar;
