
import { Link } from "react-router-dom";
const Eventcard =({event})=>
{  
    
    return(
      
      <Link to={`/${event.link}`}>
        <div className="w-72 md:w-72 h-96 md:h-96  flex flex-col  ">
        <div className="card-image w-full h-96 relative overflow-hidden  border-8 border-gray-950">
      
          <img
            src={event.img}
            className="absolute object-fill  h-full w-full  z-10"
            alt="logo"
          />
        </div>
    </div> 
    </Link>  
  
    )
}

export default Eventcard;
