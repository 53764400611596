import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Button } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {eventlist} from '../data/eventlist';
import Eventcard from './Eventcard';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Eventhome = () => {
  const settings = {
    dots:false,
    infinite:true,
    speed:500,
    slidesToShow:3.7,
    slidesToScroll:1,
    autoplay: true,
    arrows:false,
    centeMode:false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1110, 
        settings: {
          slidesToShow: 3.2, 
        },
      },
      {
        breakpoint: 955, 
        settings: {
          slidesToShow: 2.7, 
        },
      },
      {
        breakpoint: 821, 
        settings: {
          slidesToShow: 1, 
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1, 
        },
      },
    ],
  }
  
  return (
    <div className='flex-col bg-[#F2F2F4] pb-8 z-50 ' style={{ fontFamily: "kayak" }}>
      <div>
        <div className=''>
          <p className='text-black text-center sm:mb-10 mb-2 font-extrabold md:text-6xl sm:text-4xl text-4xl pt-16 pb-16'>Workshop</p>
          <div className='w-full '>
          <div className='mt-0 mb-4'>
          <Slider {...settings}>
          {eventlist.map((event, index) => 
            event.type === "event" ? (
                <div className='' key={event.id}>
                    <div className='flex justify-center items-center'> 
                        <Eventcard event={event} /> 
                    </div>
                </div>
            ) : null
        )}
          </Slider>
        </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Eventhome;
