import React from 'react';

const Hero = () => {
    return (
        <div className='bg-[#E3FF0D]  md:min-h-screen  flex justify-center content-center min-h-screen -16  z-0 overflow-hidden' style={{ fontFamily: "kayak" }}>
            <img src='images/pythonlogo.png' alt='python' className='sm:h-96 sm:w-96 w-64 h-64 -left-20 absolute sm:-bottom-8 -bottom-6 rotate-45 overflow-hidden'></img>


            <div className='flex-col'>
                <div className='flex flex-col justify-center items-center h-full sm:text-9xl text-6xl text-[#007C00] gap-20'>
                    <div className='flex'><h1 className=''>Py</h1><img src='images/pythonlogo.png' alt='python' className='h-20 w-20 sm:h-32 sm:w-32'></img><h1 className=''>week</h1></div>
                    <div className='flex flex-col'><div className='text-xl  sm:-ml-0 '><h3 className='sm:text-3xl text-2xl'> 21 - 31 </h3></div><div className='text-xl  sm:-ml-0 '><h3 className='sm:text-3xl text-2xl'>March </h3></div></div>
                </div>
                
            </div>
        </div>
    )
}

export default Hero;
