import React from 'react'
import Hero from './Hero'
import Eventhome from './Eventhome'
import Competitionhome from './Competitonhome'

const Home = () => {
  return (
    <div>
        <Hero />
        <Eventhome />
        <Competitionhome />
    </div>
  )
}

export default Home