import { useParams } from "react-router-dom";
import {eventlist} from "../data/eventlist";
import Eventcard from "./Eventcard";
import { Link } from "react-router-dom";
function Itempage() {
  const { slug } = useParams();
  const eventdata = eventlist.find((event) => event.link === slug);
  
  return (
    <div className="h-full w-full p-2 md:pt-20 bg-[#E3FF0D] " style={{ fontFamily: "kayak" }}>
      <div className="bg-[#F2F2F4] h-full w-full flex flex-col p-4 pt-16 gap-4">
        <div className="flex justify-center items-center mb-10 ">
          <div className="flex justify-center   h-64 w-64 ">
            <Eventcard event={eventdata} />
          </div>
        </div>
        <div className="flex justify-start md:justify-center   pt-28 md:text-center p-2  ">
          <div className="flex-col  ">
            <h1 className="sm:text-4xl text-2xl  font-bold text-left md:text-center">Event </h1>
            <h1 className="sm:text-3xl text-xl  text-left md:text-center">{eventdata?.name}</h1>
          </div>
        </div>
        <div className="flex justify-start md:justify-center p-2   ">
          <div className="flex-col ">
            <h1 className="sm:text-4xl text-2xl font-bold text-left md:text-center   ">Description</h1>
            <div className="flex md:justify-center md:items-center justify-start" ><h1 className="sm:text-2xl text-xl  md:text-center w-5/6 whitespace-pre-wrap ">{eventdata?.discription}</h1></div>
          </div>
        </div>
        <div className="flex justify-start md:justify-center p-2   ">
          <div className="flex-col">
            <h1 className="sm:text-4xl text-2xl  font-bold text-left md:text-center">Date</h1>
            <h1 className="sm:text-3xl text-xl  text-left md:text-center">{eventdata?.date}</h1>
          </div>
        </div>
        <div className="flex justify-start md:justify-center   ">
          <div className="flex-col">
          <h1 className="sm:text-4xl text-2xl  font-bold text-left md:text-center p-2 " >Fee</h1>
            {eventdata?.isPaid ? (
              <div className="p-2">
                <h1 className="text-xl  md:text-center ">ieiMember: {eventdata?.price.ieiMember}</h1>
                <h1 className="text-xl md:text-center ">NonieiMember: {eventdata?.price.nonieiMember}</h1>
              </div>
            ) : (
              <>
                <h1 className="text-xl md:text-center  w-5/6 p-2">Free</h1>
              </>
            )}

          </div>
        </div>
        {eventdata?.venue ===""?<></>:<div className="flex justify-start md:justify-center md:text-center p-2">
        <div className="flex-col">
          <h1 className="sm:text-4xl text-2xl  font-bold text-left md:text-center">Venue</h1>
          <h1 className="text-xl  text-left md:text-center p-2">{eventdata?.venue}</h1>
        </div>
      </div>}
        
        <div className="flex justify-start md:justify-center   ">
          <div className="flex-col p-2">
            <h1 className="sm:text-4xl text-2xl  font-bold text-left md:text-center ">Time</h1>
            <h1 className="text-xl  text-left md:text-center p-2 ">{eventdata?.time}</h1>
          </div>
        </div>
        <div className="flex justify-start md:justify-center md:text-center p-2   ">
          <div className="flex-col">
            <h1 className="sm:text-4xl text-2xl text-left md:text-center">coordinators</h1>
            <div className="flex gap-5 text-right">
              <h1 className="text-xl  text-left   w-5/6 md:text-center">{eventdata?.coordinators[0][0]}</h1>
              <h1 className="text-xl  text-left sm:w-1/2 w-5/6 md:text-center">{eventdata?.coordinators[0][1]}</h1>
            </div>
            <div className="flex gap-5 text-right ">
              <h1 className="text-xl  text-left sm:w-1/2 w-5/6 md:text-center">{eventdata?.coordinators[1][0]}</h1>
              <h1 className="text-xl  text-leftw-5/6 md:text-center">{eventdata?.coordinators[1][1]}</h1>
            </div>

          </div>
        </div>
        <div className="flex justify-center items-center">
          <div className="flex justify-center    ">
          
          {eventdata?.isRegistrationOpen?<a href={`https://www.ieicsetkmce.in/Register/${eventdata?.link}`}><button className="bg-nav-bg text-white font-bold p-4 w-40 bg-cyan-500 rounded-full hover:text-black">
          Register
        </button></a>:<><button className="bg-nav-bg text-white font-bold p-4 w-40 bg-cyan-500 rounded-full ">
        Closed
      </button></>}
            
          </div>
        </div>

      </div>
    </div>
  );
}

export default Itempage;
