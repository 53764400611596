import React from 'react';
import {eventlist} from '../data/eventlist';
import Eventcard from './Eventcard';

const Third = () => {
    return (
        <div className='bg-[#232323] h-fit w-full z-50 ' style={{ fontFamily: "kayak" }}>
            <div className='flex-col pt-20'>
                <div className='flex flex-col items-center pt-20'>
                    <h1 className="text-4xl text-center text-[#E3FF0D]">Competition</h1>
                </div>
                <div className='pl-4 pr-4 mt-12 md:mt-10 flex justify-center'> 
                    <div className='grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 justify-center items-center gap-12 sm:gap-8 md:gap-8 lg:gap-8 pb-5'> 
                    {eventlist.map((event, index) => 
                        event.type === "competition" ? (
                            <div className='' key={event.id}>
                                <div className='flex justify-center items-center'> 
                                    <Eventcard event={event} /> 
                                </div>
                            </div>
                        ) : null
                    )}
                    
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Third;
