import './App.css';
import { Routes, Route } from "react-router-dom";
import Home from './component/Home';
import Eventpg from './component/Eventpg';
import Third from './component/Third';
import Itempage from './component/Itempage';
function App() {
  return (
    <div >
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/workshop' element={<Eventpg />} />
        <Route path='/competition' element={<Third />} />
        <Route path="/:slug" element={<Itempage />} />
        
      </Routes>
    </div>
  );
}

export default App;
