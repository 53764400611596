export  const  eventlist=[
    {
        id:1,
        name:'Basics of Python',
        type:'event',
        discription:" 🐍 Get ready to unleash the power of Python at PYWEEK by IEI CSE TKMCE – Join us for an enlightening workshop on ' Basics Of Python ' , dedicated to unraveling the fundamentals of Python programming. \n\nIn this engaging session, participants will explore key concepts of programming on Python , laying a strong foundation for their Python journey. Our session and hands-on activities ensure an enriching learning experience for all attendees. ",
        img:'/images/event1.jpeg',
        link:'Basic-of-Python',
        isRegistrationOpen:false,
        date:'March 21,2024',
        isTeamevent:false,
        teammember:1,
        isPaid:false,
        enddate:'',
        price:{
            ieiMember:0,
            nonieiMember:0
        },
        time:'7:30 - 9:30 pm',
        venue:'Gmeet',
        coordinators: [
             ["Devna M Lal","8921595525"],
             ["Sreya Hari", "7012697709"]
    ],
        
    },
    {
        id:2,
        name:'Data Science Workshop',
        type:'event',
        discription:" 👨🏻‍💻Dive into the world of data science with our interactive workshop designed to introduce you to fundamental concepts and techniques.\n\nAs part of PYWEEK, IEI CSE TKMCE brings you 'Data Science Workshop', where you'll learn how to analyze, visualize, and derive insights from complex datasets📊 alongside learn about various data science concepts, tools and techniques. Demystify the world of data science and gain a clear understanding of its concepts .\n\n💻📈Immerse yourself in a comprehensive data science experience with our workshop designed to equip you with the skills needed for success. ",
        img:'/images/event2.jpeg',
        link:'Data-Science-Workshop',
        isRegistrationOpen:false,
        date:'March 22',
        isTeamevent:false,
        teammember:1,
        isPaid:false,
        enddate:'',
        price:{
            ieiMember:0,
            nonieiMember:0
        },
        time:'5:00 - 6:30 pm',
        venue:'Gmeet',
        coordinators: [
            ["Sneha","7356492394"],
            ["Shikha", "9400414005"]
   ],
       
        
        referalId:false,
    },
    {
        id:3,
        name:'Exploring Generative AI with LLM',
        type:'event',
        discription:"🚀Gear up to explore the realm of Generative AI with LLM at PYWEEK by IEI CSE TKMCE . Join us for Exploring Generative AI with LLM , an enlightening workshop dedicated to unraveling the mysteries of generative artificial intelligence.\n\nEmbark on this journey led by Risal Rasheed , an Associate Software Engineer at KeyValue Software Systems , who will guide you through fundamental concepts and engaging discussions.",
        img:'/images/event5.jpeg',
        link:'Exploring-Generative-AI-with-LLM',
        isRegistrationOpen:false,
        date:'23 March',
        isTeamevent:false,
        teammember:1,
        isPaid:false,
        enddate:'',
        price:{
            ieiMember:0,
            nonieiMember:0
        },
        time:' 3:00 - 5:00 pm',
        venue:'Gmeet',
        coordinators: [
            ["Mareena Joseph ","+917510701853"],
            ["Anu Thomson", "+919562607325"]
    ]
       
    },
      
    {
        id:4,
        name:'Deep Learning Workshop',
        type:"event",
        discription:"🧠💡 Explore the Frontiers of Innovation with Deep Learning Workshop as a part of PyWeekby by IEI CSE TKMCE.  Embark on an electrifying journey into the heart of artificial intelligence and neural networks.\n\nIn this enlightening session, unlock the secrets of deep learning and dive into its limitless possibilities. From revolutionizing computer vision to mastering natural language processing, immerse yourself in the cutting-edge technology shaping our future!",
        img:'/images/event3.jpg',
        link:'Deep-Learning-Workshop',
        isRegistrationOpen:false,
        date:'24 March',
        isTeamevent:false,
        teammember:1,
        isPaid:false,
        enddate:'',
        price:{
            ieiMember:0,
            nonieiMember:0
        },
        time:'7:30 PM - 9:30 PM',
        venue:'Gmeet',
        coordinators: [
            ["Akhila ","8590885459"],
            ["Shihabudheen", "8547177987"]
],
},
    
 {
        id:6,
        name:'Model Forge',
        type:'competition',
        discription:" 🚀 Get ready to immerse yourself in an adrenaline-fueled 24-hour machine learning competition with Model Forge brought to you as part of PyWeek conducted by IEI CSE TKMCE.\n\n🎉 Join us for an epic battle to claim the ML champion title. 💻 Train your models, submit predictions, and ascend the leaderboard to secure victory! 🏆 Don't miss out on this thrilling opportunity to showcase your skills and compete against the best in the field. 🔥 🗓️ Date: 24 March 2024\n\n🕘 Time: 9:00 am\n🛑 Mode: Online\n🏆 First Prize: ₹500\n📍 Reg fee:₹50\n🤩 Free for IEI members",
        img:'/images/com1.png',
        link:'Model-Forge',
        isRegistrationOpen:false,
        date:'24 March',
        isTeamevent:false,
        teammember:1,
        isPaid:true,
        enddate:'',
        price:{
            ieiMember:0,
            nonieiMember:50
        },
        time:'March 24,9:00 am - 25,9:00 am',
        venue:'',
        coordinators: [
            ["Muhammed Sinan","7356492394"],
            ["Stanzin Chozen", "9103228985"]
   ],
       
        pref1:[
            
         ],
         pref2:[],
        referalId:false,
    },
 
{
    id:7,
    name:'Django Workshop',
    type:'event',
    discription:" 🌟 Step into the realm of web development magic with Django! 🌟\n\nUnleash your creativity and ignite innovation as you embark on an immersive journey guided by the powerful Django framework.🐍 Join us for an exclusive Django Workshop presented by PYWEEK , hosted by IEI CSE TKMCE .\n\n🚀 Discover the secrets of crafting dynamic web applications with Django's clean and pragmatic design.\n\nMark your calendars and reserve your spot today!",
    img:'/images/event6.jpeg',
    link:'Django-Workshop',
    isRegistrationOpen:true,
    date:'29 March 2024 ',
    isTeamevent:false,
    teammember:1,
    isPaid:false,
    enddate:'',
    price:{
        ieiMember:0,
        nonieiMember:0
    },
    time:'7:30 pm to 9:30 pm ',
    venue:'Gmeet',
    coordinators: [
        ["Muhammed Noufal N","9895756635"],
        ["Irish Ann Rajan", "8606079720"]
],
   
    
    referalId:false,
},
{
    id:8,
    name:'Gen AI Image Generation',
    type:'event',
    discription:"🚀Gear up to explore the realm of Generative AI with LLM at PYWEEK by IEI CSE TKMCE . Join us for Pixel Craft , an enlightening workshop dedicated to exploring and experiencing the craft of prompt engineering.\n\nEmbark on this journey led by Amal Nath , an Associate Software Engineer at KeyValue Software Systems , who will guide you through fundamental concepts and engaging discussions.\n\n📆Date: March 27, 2024\n 🕧Time: 8:30pm-9:30pm \n📍Mode: Online ",
    img:'/images/event7.jpeg',
    link:'Gen-AI-Image-Generation',
    isRegistrationOpen:false,
    date:'March 27, 2024',
    isTeamevent:false,
    teammember:1,
    isPaid:false,
    enddate:'',
    price:{
        ieiMember:0,
        nonieiMember:0
    },
    time:'8:30pm-9:30pm',
    venue:'Gmeet',
    coordinators: [
        ["Siya Pillai","9497246124"],
        ["Rameesa MK", "8281900584"]
],
   
    
    referalId:false,
},
{
    id:6,
    name:'🎨 Artifex: Where Creativity Meets Competition! 🖌️',
    type:'competition',
    discription:"Calling all artists and enthusiasts! Join us for Artifex, an exhilarating image generation competition hosted by IEI CSE TKMCE as part of Pyweek.\n\n 🤩Unleash your imagination and create captivating images based on themed prompts. Whether you're a seasoned artist or just starting, Artifex welcomes all skill levels!🔥\n🗓️ Date: March 28th\n 🕙 Time: 10:00 AM - 10:00 PM\n💰 Prizes Worth Rs. 250\n📝 Registration: Rs. 25\n🤩 Free for IEI Members📍",
    img:'/images/com2.png',
    link:'Artifex-Where-Creativity-Meets-Competition',
    isRegistrationOpen:false,
    date:'March 28th',
    isTeamevent:false,
    teammember:1,
    isPaid:true,
    enddate:'',
    price:{
        ieiMember:0,
        nonieiMember:25
    },
    time:'10:00 am - 10:00 pm',
    venue:'',
    coordinators: [
        ["Muhammed Fayis "," 8129229935"],
        ["Shahina Shanavas", "6235730260"]
],
   
    pref1:[
        
     ],
     pref2:[],
    referalId:false,
},

]
